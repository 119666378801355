import { asText } from '@prismicio/helpers';
import { styled, Box } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import type { FC } from 'react';
import type { HomeData } from '@/utils/prismic/typings';

import CustomerReviewItem from '@/c/generic/CustomerReviewItem';
import GenericDividingTitle from '@/c/generic/GenericDividingTitle';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';

const SwiperContainer = styled(Box)({
  '& .swiper-pagination-bullet': {
    border: '1px solid #000000',
    opacity: 1,
    background: 'transparent',
  },
  '& .swiper-pagination-bullet-active': {
    background: '#000000',
  },
});

interface CustomerReviewsGalleryProps {
  customerReviews: HomeData['customerReviews'],
}
const CustomerReviewsGallery: FC<CustomerReviewsGalleryProps> = ({ customerReviews }) => (
  <div className="space-y-4 sm:space-y-10">
    <GenericDividingTitle>業主評價</GenericDividingTitle>
    <SwiperContainer className="px-2 sm:px-20">
      <Swiper
        modules={[Pagination, Autoplay]}
        autoplay={{ delay: 3888 }}
        pagination={{ clickable: true }}
      >
        {customerReviews.map((review) => (
          <SwiperSlide key={asText(review.comment)}>
            <CustomerReviewItem
              comment={review.comment}
              subtitle={review.subtitle}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </SwiperContainer>
  </div>
);

export default CustomerReviewsGallery;
