import Image from 'next/image';

import chiefDesignerTimPic from '@/assets/cheif-designer-tim.jpg';

const ChiefDesignerQuoteForMobile = () => (
  <div className="block sm:hidden">
    <div className="bg-gray-200">
      {/* Picture */}
      <div className="relative">
        <Image
          src={chiefDesignerTimPic}
          alt="Chief Designer Tim"
          placeholder="blur"
        />
        <div className="absolute bottom-0 w-full h-[80%] bg-gradient-to-t from-gray-200" />
        <div className="absolute bottom-0 w-full">
          <div className="py-6 px-6 flex flex-col justify-center">
            <div className="space-y-5">
              <div className="flex justify-start">
                <div className="text-lg">對於每一個空間的期許</div>
              </div>
              <div className="text-2xl xxs:text-3xl space-y-2 tracking-wider">
                <div className="flex">“注意五感與自然，</div>
                <div className="flex justify-end">溫度與空間的設計”</div>
              </div>
              <div className="flex justify-end">
                <div className="text-lg">設計總監－鍾鼎及鍾黎</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Quote */}
      <div className="py-6 px-4 flex flex-col justify-center space-y-6">
        <div className="space-y-3 text-sm text-gray-900 tracking-wide leading-relaxed font-light">
          <p>A Design For Life</p>
          <p>
            透過本質上保留簡潔性，創造出空間、時間與光感脈絡，好宅不需要過多綴飾，
            有了好採光、通風及良好動線鋪排，搭配家具跟恰當軟件，再加上屋主，就能圓滿整個居所。
            如同生活沒有既定公式，室內設計也無框架規則；
            拋棄浮華裝飾，我們著重於人與人、人與環境連結產生的相互關係，
            期望不管三個月、十年，居住者在每個生命階段，對於空間都能產生不同情感與定義。
          </p>
          <p>
            我們重新審視每件事物，以獨特觀看角度，了解每件事物價值；
            並隨時注意其中細微變化，追求完美意象。
            奧立佛室內設計 x 竺居設計聯合工作團隊，
            悉心看待每一層結構間的動靜環節，
            將永續自然、人與空間列為設計宗旨。
          </p>
        </div>
      </div>
    </div>
  </div>
);

const ChiefDesignerQuoteForDesktop = () => (
  <div className="hidden sm:block">
    <div className="grid grid-cols-5">
      {/* Picture */}
      <div className="col-span-2">
        <Image
          src={chiefDesignerTimPic}
          alt="Chief Designer Tim"
          placeholder="blur"
        />
      </div>
      {/* Quote */}
      <div className="col-span-3 flex flex-col justify-center pl-12 lg:pl-20 space-y-8 lg:space-y-12">
        <div className="space-y-5 lg:space-y-9">
          <div className="flex justify-start">
            <div className="text-lg lg:text-2xl">對於每一個空間的期許</div>
          </div>
          <div className="text-3xl lg:text-4xl space-y-2 lg:space-y-4 px-6 lg:px-10 xl:px-20 tracking-wider">
            <div className="flex">“注意五感與自然，</div>
            <div className="flex justify-end">溫度與空間的設計”</div>
          </div>
          <div className="flex justify-end">
            <div className="text-lg lg:text-2xl">設計總監－鍾鼎及鍾黎</div>
          </div>
        </div>
        <div className="space-y-2 lg:space-y-3 text-sm text-gray-700 tracking-wide leading-relaxed font-light">
          <p>A Design For Life</p>
          <p>
            透過本質上保留簡潔性，創造出空間、時間與光感脈絡，好宅不需要過多綴飾，
            有了好採光、通風及良好動線鋪排，搭配家具跟恰當軟件，再加上屋主，就能圓滿整個居所。
            如同生活沒有既定公式，室內設計也無框架規則；
            拋棄浮華裝飾，我們著重於人與人、人與環境連結產生的相互關係，
            期望不管三個月、十年，居住者在每個生命階段，對於空間都能產生不同情感與定義。
          </p>
          <p>
            我們重新審視每件事物，以獨特觀看角度，了解每件事物價值；
            並隨時注意其中細微變化，追求完美意象。
            奧立佛室內設計 x 竺居設計聯合工作團隊，
            悉心看待每一層結構間的動靜環節，
            將永續自然、人與空間列為設計宗旨。
          </p>
        </div>
      </div>
    </div>
  </div>
);

const ChiefDesignerQuote = () => (
  <>
    <ChiefDesignerQuoteForMobile />
    <ChiefDesignerQuoteForDesktop />
  </>
);

export default ChiefDesignerQuote;
