import clsx from 'clsx';
import { asText } from '@prismicio/helpers';
import { PrismicRichText } from '@prismicio/react';
import type { FC, PropsWithChildren } from 'react';
import type { HomeData } from '@/utils/prismic/typings';

import { linkResolver } from '@/utils/prismic/helpers';
import GenericDividingTitle from '@/c/generic/GenericDividingTitle';

interface LinkItemProps {
  href: string,
  variant: 'primary' | 'secondary',
}
const LinkItem: FC<PropsWithChildren<LinkItemProps>> = ({ children, href, variant }) => (
  <div
    className={clsx(
      'relative w-full pb-[100%] border sm:border-2 border-black',
      'transition-all duration-100',
      'hover:font-medium hover:text-lg',
      variant === 'secondary' && 'bg-black text-white',
    )}
  >
    <a
      href={href}
      className="absolute inset-0 flex justify-center items-center tracking-wider"
    >
      {children}
    </a>
  </div>
);

interface ServicesGridProps {
  serviceDescriptions: HomeData['serviceDescriptions'],
  services: HomeData['services'],
}
const ServicesGrid: FC<ServicesGridProps> = ({ serviceDescriptions, services }) => (
  <div className="space-y-4 sm:space-y-6 flex flex-col items-center">
    <GenericDividingTitle>服務項目</GenericDividingTitle>
    <div className="text-center leading-relaxed">
      <PrismicRichText field={serviceDescriptions} />
    </div>
    <div className="grid grid-cols-3 gap-2 sm:gap-4 w-full max-w-xs xs:max-w-sm sm:max-w-lg px-2 xxs:px-0">
      {services.map((service) => (
        <LinkItem
          href={linkResolver(service.category)}
          variant="primary"
          key={service.category.id}
        >
          {asText(service.category.data.name)}
        </LinkItem>
      ))}
      <LinkItem variant="secondary" href="/projects/category/all">瞭解更多</LinkItem>
    </div>
  </div>
);

export default ServicesGrid;
