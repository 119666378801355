import { FC, PropsWithChildren } from 'react';
import clsx from 'clsx';

const GenericDividingTitle: FC<PropsWithChildren<{}>> = ({ children }) => (
  // Container for centered content
  <div className="relative w-full flex justify-center px-10 sm:mt-8">
    {/* The content */}
    <div className="relative w-full max-w-[500px] flex justify-center">
      {/* Divided line */}
      <div
        className={clsx(
          'absolute top-[50%] -translate-y-[50%]',
          'w-full h-[1px] bg-black',
        )}
      />
      {/* Title */}
      <div
        className={clsx(
          'py-2 px-4 sm:px-6 z-10 bg-white',
          'font-normal text-xl sm:text-2xl',
        )}
      >
        {children}
      </div>
    </div>
  </div>
);

export default GenericDividingTitle;
