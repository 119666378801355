import clsx from 'clsx';
import { PrismicRichText } from '@prismicio/react';
import type { FC } from 'react';
import type { RichTextField } from '@prismicio/types';

const CommentParagraph = ({ children, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <p className="leading-6 sm:leading-7" {...props}>{children}</p>
);

interface CustomerReviewItemProps {
  comment: RichTextField,
  subtitle: RichTextField,
}
const CustomerReviewItem: FC<CustomerReviewItemProps> = ({ comment, subtitle }) => (
  <div className="relative">
    <div
      className={clsx(
        'absolute top-0 font-black text-gray-300 sm:text-black leading-none',
        'top-[10px] left-[-8px] text-[30px]',
        'sm:left-[-26px] sm:text-[80px]',
      )}
    >
      “
    </div>
    <div
      className={clsx(
        'absolute font-black text-gray-300 sm:text-black leading-none',
        'bottom-[10px] right-[-8px] text-[30px]',
        'sm:bottom-[-25px] sm:right-[-26px] sm:text-[80px]',
      )}
    >
      ”
    </div>
    <div className="py-10 sm:py-16 px-2 sm:px-10 space-y-2.5 sm:space-y-5">
      {!!comment && (
        <div className="text-base sm:text-xl text-center space-y-1 tracking-normal sm:tracking-wide">
          <PrismicRichText
            field={comment}
            components={{
              paragraph: CommentParagraph,
            }}
          />
        </div>
      )}
      {!!subtitle && (
        <div className="text-center text-sm sm:text-base font-light text-gray-500 sm:text-black tracking-wide">
          <PrismicRichText field={subtitle} />
        </div>
      )}
    </div>
  </div>
);

export default CustomerReviewItem;
