import Image from 'next/image';
import type { FC } from 'react';
import type { HomeData } from '@/utils/prismic/typings';

interface HeroImageProps {
  heroImage: HomeData['heroImage'],
}
const HeroImage: FC<HeroImageProps> = ({ heroImage }) => (
  heroImage?.url ? (
    <div className="container mx-auto flex">
      <Image
        layout="intrinsic"
        alt={heroImage.alt}
        src={heroImage.url}
        width={heroImage.dimensions.width}
        height={heroImage.dimensions.height}
      />
    </div>
  ) : null
);

export default HeroImage;
