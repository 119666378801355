import * as prismic from '@prismicio/client';
import type { FC } from 'react';
import type { GetStaticProps } from 'next';
import type { Home, Project } from '@/utils/prismic/typings';

import Title from '@/c/common/Title';
import Footer from '@/c/common/Footer';
import NavBar from '@/c/common/NavBar';
import HeroImage from '@/c/home/HeroImage';
import Description from '@/c/common/Description';
import ServicesGrid from '@/c/home/ServicesGrid';
import FeaturedProjects from '@/c/home/FeaturedProjects';
import ChiefDesignerQuote from '@/c/home/ChiefDesignerQuote';
import GenericDividingTitle from '@/c/generic/GenericDividingTitle';
import CustomerReviewsGallery from '@/c/home/CustomerReviewsGallery';
import AwardWinningProjectsGallery from '@/c/home/AwardWinningProjectsGallery';
import { getPrismicClient } from '@/utils/prismic/helpers';

interface StaticProps {
  home: Home,
  featuredProjects: Project[],
  awardWinningProjects: Project[],
}

const Page: FC<StaticProps> = ({ home, featuredProjects, awardWinningProjects }) => (
  <>
    <Title />
    <Description>
      透過本質上保留簡潔性，創造出空間、時間與光感脈絡，好宅不需要過多綴飾，
      有了好採光、通風及良好動線鋪排，搭配家具跟恰當軟件，再加上屋主，就能圓滿整個居所。
      如同生活沒有既定公式，室內設計也無框架規則； 拋棄浮華裝飾，我們著重於人與人、人與環境連結產生的相互關係，
      期望不管三個月、十年，居住者在每個生命階段，對於空間都能產生不同情感與定義。
    </Description>
    <NavBar />
    <div className="max-w-6xl mx-auto">
      <div className="sm:container mx-auto py-10 sm:py-14 space-y-10 sm:space-y-20">
        <HeroImage heroImage={home.data.heroImage} />
        <CustomerReviewsGallery customerReviews={home.data.customerReviews} />
        <ServicesGrid
          services={home.data.services}
          serviceDescriptions={home.data.serviceDescriptions}
        />
        <FeaturedProjects projects={featuredProjects} />
        <ChiefDesignerQuote />
        <div className="space-y-4 sm:space-y-6">
          <GenericDividingTitle>得獎作品</GenericDividingTitle>
          <AwardWinningProjectsGallery awardWinningProjects={awardWinningProjects} />
        </div>
      </div>
    </div>
    <Footer />
  </>
);

type GetStaticPropsType = GetStaticProps<StaticProps, {}, { ref?: string }>;
export const getStaticProps: GetStaticPropsType = async ({ previewData }) => {
  const prismicClient = getPrismicClient();

  const home = await prismicClient.getSingle<Home>('home', {
    // Support preview mode, pass preview ref to get preview data
    ref: previewData?.ref,
    fetchLinks: [
      'category.name',
    ],
  });

  const featuredProjects = await prismicClient.getAllByType<Project>('project', {
    pageSize: 6,
    orderings: ['my.project.launchDate desc'],
    fetchLinks: ['category.name'],
    predicates: [
      // @ts-ignore: Prismic typing bug
      prismic.predicate.at('my.project.featured', true),
    ],
  });

  const awardWinningProjects = await prismicClient.getAllByType<Project>('project', {
    pageSize: 20,
    orderings: ['my.project.launchDate desc'],
    predicates: [
      prismic.predicate.dateAfter('my.project.awards.announcedDate', '1991-01-01'),
    ],
  });

  return {
    props: {
      home,
      featuredProjects,
      awardWinningProjects,
    },
    revalidate: 60,
  };
};

export default Page;
