import type { FC } from 'react';
import type { Project, LinkedProjectDocument } from '@/utils/prismic/typings';

import ProjectItem from '@/c/generic/ProjectItem';
import GenericButton from '@/c/generic/GenericButton';
import GenericDividingTitle from '@/c/generic/GenericDividingTitle';

const SeeMoreButton = (props) => (
  <GenericButton
    fullWidth
    variant="primary"
    className="sm:max-w-[288px]"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    看更多作品
  </GenericButton>
);

interface FeaturedProjectsProps {
  projects: Project[] | LinkedProjectDocument[],
}

const FeaturedProjects: FC<FeaturedProjectsProps> = ({ projects }) => (
  <div className="space-y-4 sm:space-y-10 px-4">
    <GenericDividingTitle>精選案例</GenericDividingTitle>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-4 sm:gap-4 lg:gap-6">
      {projects.map((project) => (
        <ProjectItem key={project.id} project={project} />
      ))}
    </div>
    <div className="text-center py-2">
      <SeeMoreButton href="/projects/category/all" />
    </div>
  </div>
);

export default FeaturedProjects;
