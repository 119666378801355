import Head from 'next/head';
import type { FC } from 'react';

interface DescriptionProps { children: string }
const Description: FC<DescriptionProps> = ({ children }) => (
  <Head>
    <meta name="description" content={children} />
    <meta property="og:description" content={children} />
  </Head>
);

export default Description;
