import clsx from 'clsx';
import Image from 'next/image';
import { FC, useMemo } from 'react';
import { styled, Box } from '@mui/material';
import { asText, asDate } from '@prismicio/helpers';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import type { Project, ProjectData } from '@/utils/prismic/typings';

import { linkResolver } from '@/utils/prismic/helpers';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';

interface ProjectWithMeta extends Project {
  lastAwardAnnouncedAt?: Date,
}

const SwiperContainer = styled(Box)({
  '& .swiper': {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  '& .swiper-pagination': {
    position: 'relative',
    bottom: 'unset!important',
    paddingTop: 8,
  },
  '& .swiper-pagination-bullet': {
    border: '1px solid #000000',
    opacity: 1,
    background: 'transparent',
  },
  '& .swiper-pagination-bullet-active': {
    background: '#000000',
  },
});

const AwardWinningProjectItem: FC<{ project: Project }> = ({ project }) => (
  <a
    href={linkResolver(project)}
    className="relative w-full flex"
  >
    <Image
      layout="intrinsic"
      src={project.data.featuredImages.url}
      alt={project.data.featuredImages.alt}
      width={project.data.featuredImages?.dimensions.width}
      height={project.data.featuredImages?.dimensions.height}
    />
    <div
      className={clsx(
        'absolute bottom-0 sm:bottom-[30px]',
        'max-w-full w-full sm:w-min py-2 px-3 sm:py-6 sm:px-8',
        'bg-gradient-to-t from-black pt-6',
        'sm:bg-black sm:bg-opacity-50 sm:from-transparent',
      )}
    >
      <div className="text-white">
        {project?.data?.awards?.map((award) => (
          award?.compactName && award?.announcedDate
            ? (
              <p
                key={asText(award.compactName)}
                className={clsx(
                  'max-w-full whitespace-nowrap overflow-ellipsis overflow-hidden',
                  'text-sm sm:text-xl leading-normal sm:leading-relaxed tracking-wide sm:tracking-wider font-light',
                )}
              >
                {/* e.g., 2021 頒獎機構 金牌 */}
                {`${asDate(award.announcedDate).getFullYear()} ${asText(award.compactName)}`}
              </p>
            )
            : null
        ))}
      </div>
    </div>
  </a>
);

interface AwardWinningProjectsGalleryProps {
  awardWinningProjects: Project[],
}
const AwardWinningProjectsGallery: FC<AwardWinningProjectsGalleryProps> = ({
  awardWinningProjects,
}) => {
  const sortedProjects = useMemo<ProjectWithMeta[]>(
    () => awardWinningProjects
      // Filter out projects without any award
      .filter((project) => !!project?.data?.awards?.length)
      // Add lastAwardAnnouncedAt to each project
      .map((project) => {
        // Get the most recent award announced date
        let lastAwardAnnouncedAt;
        project.data.awards.forEach((award: ProjectData['awards'][0]) => {
          if (!award.announcedDate) return;
          const announcedDate = new Date(award.announcedDate);
          if (!lastAwardAnnouncedAt || announcedDate > lastAwardAnnouncedAt) {
            lastAwardAnnouncedAt = announcedDate;
          }
        });

        return {
          ...project,
          lastAwardAnnouncedAt,
        };
      })
      // Sort by lastAwardAnnouncedAt, descending (new -> old)
      .sort((a, b) => b.lastAwardAnnouncedAt - a.lastAwardAnnouncedAt),
    [awardWinningProjects],
  );

  return (
    <div className="space-y-4 sm:space-y-10">
      <SwiperContainer>
        <Swiper
          modules={[Pagination, Autoplay]}
          autoplay={{ delay: 3888 }}
          pagination={{ clickable: true }}
        >
          {sortedProjects.map((project) => (
            <SwiperSlide key={project.uid}>
              <AwardWinningProjectItem project={project} />
            </SwiperSlide>
          ))}
        </Swiper>
      </SwiperContainer>
    </div>
  );
};

export default AwardWinningProjectsGallery;
