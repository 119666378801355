import clsx from 'clsx';
import Image from 'next/image';
import { Skeleton } from '@mui/material';
import { FC, useMemo } from 'react';
import { PrismicText } from '@prismicio/react';
import { asDate, asText } from '@prismicio/helpers';
import type { Project } from '@/utils/prismic/typings';

import { linkResolver } from '@/utils/prismic/helpers';

const ProjectItem: FC<{ project: Project }> = ({ project }) => {
  const infoItems = useMemo(() => {
    const items = [];
    if (project?.data?.city) items.push(asText(project.data.city));
    if (project?.data?.launchDate) items.push(asDate(project.data.launchDate).getFullYear());
    if (project?.data?.category?.data?.name) items.push(asText(project.data.category.data.name));
    if (project?.data?.size) items.push(asText(project.data.size));
    return items;
  }, [project]);

  return (
    <a
      className={clsx(
        'block space-y-2',
        // Add saturate filter when hovering over this block
        'hover:filter hover:saturate-150 transition duration-300 ease-in-out',
      )}
      key={project.id}
      href={`${linkResolver(project)}`}
    >
      {!!project?.data?.featuredImages?.cover && (
        <div className="">
          <Image
            layout="responsive"
            src={project.data.featuredImages.cover.url}
            alt={project.data.featuredImages.cover?.alt}
            width={project.data.featuredImages.cover?.dimensions?.width}
            height={project.data.featuredImages.cover?.dimensions?.height}
          />
        </div>
      )}
      <div
        className={clsx(
          'w-full p-4',
          'border border-black',
          'text-center',
        )}
      >
        {!!project?.data?.title && (
          <h3 className="text-xl tracking-wide leading-relaxed">
            <PrismicText field={project.data.title} />
          </h3>
        )}
        <div className="text-sm text-gray-900 tracking-wide">
          {infoItems.map((item, index) => (
            <span key={item}>
              {item}
              {/* Add dot behind if this is not latest item */}
              {(infoItems.length - 1) !== index && '・'}
            </span>
          ))}
        </div>
      </div>
    </a>
  );
};

export const ProjectItemSkeleton = () => (
  <div className="space-y-2">
    {/* The image */}
    <div className="w-full relative">
      <div className="w-full pt-[66.66%] " />
      <div className="absolute inset-0 w-full h-full bg-gray-100 animate-pulse" />
    </div>
    {/* The info box */}
    <div className="w-full p-4 border border-black text-center">
      <h3 className="text-xl">
        <Skeleton />
      </h3>
      <div className="text-sm">
        <Skeleton />
      </div>
    </div>
  </div>
);

export default ProjectItem;
