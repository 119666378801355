import { FC, ButtonHTMLAttributes, AnchorHTMLAttributes } from 'react';
import clsx from 'clsx';

type GenericButtonProps = FC<ButtonHTMLAttributes<{}> & AnchorHTMLAttributes<{}> & { variant: 'primary' | 'secondary', fullWidth?: boolean }>;
const GenericButton: GenericButtonProps = ({
  children, variant, fullWidth, href, ...props
}) => (
  !!href && (
    <a
      href={href}
      target={props?.target || '_self'}
      className={clsx(
        'inline-block w-auto py-3 px-8',
        'text-center tracking-wider',
        'transition duration-300 ease-in-out',
        // Options
        !!fullWidth && '!w-full',
        // For variants
        variant === 'primary' && 'bg-black text-white hover:bg-gray-900 border-2 border-transparent',
        variant === 'secondary' && 'bg-white text-black border-2 border-black hover:bg-gray-100',
        // className overrides
        props?.className,
      )}
    >
      {children}
    </a>
  )
);

export default GenericButton;
